<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Caixas</h3>
        </div>
        <div class="card-body">

          <b-table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              :items="lista_finan_caixas"
              show-empty
              empty-text="Nenhum registro encontrado!"
              :fields="['id','nome','data_caixa', 'acoes']"
          >
             <template #cell(data_caixa)="{item}">
              <div>
                {{ item.data_caixa | formatDBDateToLocale }}
              </div>
            </template>
            <template #head(acoes)=""><span></span></template>

            <template #cell(acoes)="{item}">
              <div class="text-right w-100">
                <button
                    v-show="lista_permissoes_filial.u_FinanCaixa"
                    @click="atualizar(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.lock_FinanCaixa"
                    @click="confirm(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/desbloquear registro"
                >
                  <i class="flaticon-lock text-danger"></i>
                </button>

                <button
                    @click="goPedidosRecebimento(item.id)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Recebimentos"
                >
                  <i class="fas fa-door-open text-success"></i>
                </button>

                <button
                    @click="goPedidoCompra(item.id)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Compra"
                >
                  <i class="fas fa-door-open text-warning"></i>
                </button>

                  <button
                    @click="trocar_pagina('relatorio')"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Relatorios"
                >
                  <i class="fas fa-table text-info"></i>
                </button>

                
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from "@/core/mixins/dateFormat";
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";

export default {
    mixins: [dateFormat],
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Caixa"}]);
  },

  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_finan_caixas() {
      return this.$store.state.financeiro.lista_finan_caixas;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_finan_moeda();
  },
  methods: {

    trocar_pagina(rota){
    
      this.$router.push({name: rota});
    },
    async listar_finan_moeda() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("financeiro/listar_finan_caixa").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    async goPedidosRecebimento(id) {
      // this.$store.dispatch("MudarPrelo ader", true);
      // let a = {caixa_id: value.id};
      // await this.$store.dispatch("guardarDados", a);
      // this.$router.push({name: "finanContasReceberCaixa"} );
         this.$router.push({name: "finanContasReceberCaixa", params: {caixa_id: id}});
      // this.$store.dispatch("MudarPreloader", false);
    },
    goPedidoCompra(id) {
      // this.$router.push({name: "finanContasPagarCaixa"});
         this.$router.push({name: "finanContasPagarCaixa", params: {caixa_id: id}});
    },
    atualizar(value) {
      this.$router.push({name: "createCaixa"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara essa moeda no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("bloquear_finan_moeda", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: #58ff87;
}
</style>